import React from 'react';
import { Typography } from '@mui/material';

interface HighlightTermsProps {
  text: string;
  terms: string[];
}

function _HighlightTerms({ text, terms }: HighlightTermsProps) {
  const parts = text.split(/\s+/);

  return (
    <>
      {parts.map((part, index) => {
        const match = terms.find((term) =>
          part.toLowerCase().startsWith(term.toLowerCase())
        );
        if (!match) {
          return part + ' ';
        }

        return (
          <React.Fragment key={index}>
            <Typography
              component={'span'}
              sx={(theme) => ({
                backgroundColor: theme.vdxColors.vdxYellow[250],
              })}
            >
              {part.substring(0, match.length)}
            </Typography>
            {part.substring(match.length)}{' '}
          </React.Fragment>
        );
      })}
    </>
  );
}
const HighlightTerms = React.memo(_HighlightTerms);

export { HighlightTerms };
