import { useEffect, useState } from 'react';
import { GridValidRowModel } from '@mui/x-data-grid-premium';
import { getRowCounts, RowCounterWorkerProps } from 'workers/getRowCounts';

// #region Types

export interface UseGetRowCountsProps<TRow extends GridValidRowModel>
  extends RowCounterWorkerProps<TRow> {
  skip?: boolean;
  loading?: boolean;
}

interface UseGetRowCountsReturn<TRow extends GridValidRowModel> {
  rowCounts: RowCounts<TRow>;
  isCalculating: boolean;
}

export type RowCounts<T extends GridValidRowModel> = Map<keyof T, PropCounts>;

export type PropCounts = Map<unknown, NumberCountObject>;

export type NumberCountObject = {
  count: number;
  rowIds: string[];
};

// #endregion

export const useGetRowCounts = <TRow extends GridValidRowModel>({
  rows,
  columns,
  skip,
  loading,
}: UseGetRowCountsProps<TRow>): UseGetRowCountsReturn<TRow> => {
  const [rowCounts, setRowCounts] = useState<RowCounts<TRow>>(new Map());
  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  useEffect(() => {
    if (!rows || !columns) return;
    if (skip || loading) return;
    if (rows.length === 0 || columns.length === 0) return;

    // workers can only get serializable properties so we must clean this
    const cleanedColumns = columns
      .filter(
        (column) =>
          typeof column.filterable === 'undefined' || column.filterable
      ) // only deal with filterable columns (undefined === filterable)
      .map(
        ({
          type,
          field,
          valueGetterId,
          valueGetterKey,
          filterValueOverrideFn,
        }) => ({
          type,
          field,
          valueGetterId,
          valueGetterKey,
          filterValueOverrideFn,
        })
      );

    const counts = getRowCounts({
      rows: rows,
      columns: cleanedColumns,
    });
    setRowCounts(counts);
    setIsCalculating(false);
    return;
  }, [rows, columns, skip, loading]);

  return {
    rowCounts,
    isCalculating,
  };
};
