import allCodeRecords from './naics2022.json';
import {
  connectCodeInfos,
  digitsOf,
  getCodeInfos,
  pruneCodeInfos,
} from './helpers';
import { NaicsCodeInfo } from './types';

/**
 * Returns a new array of code infos with parent-child relationships set up.
 */
export function newCodeInfos() {
  const result: NaicsCodeInfo[] = allCodeRecords.map(({ code, title }) => ({
    code,
    title,
    children: {},
  }));

  return connectCodeInfos(result);
}

const _allCodeInfosFlat = newCodeInfos();

export const allCodeInfos = Object.fromEntries(
  _allCodeInfosFlat.map((info) => [info.code, info])
);

export const sectors = getCodeInfos(2, _allCodeInfosFlat);
export const subsectors = getCodeInfos(3, _allCodeInfosFlat);
export const industryGroups = getCodeInfos(4, _allCodeInfosFlat);
export const industries = getCodeInfos(5, _allCodeInfosFlat);
export const nationalIndustries = getCodeInfos(6, _allCodeInfosFlat);

/**
 * Get a hierarchy limited to the given depth, with only children removed.
 */
export function newPrunedCodeInfos({
  minDigits,
  maxDigits,
  pruneLeaves = true,
}: {
  minDigits: number;
  maxDigits?: number;
  pruneLeaves?: boolean;
}) {
  if (maxDigits == null) {
    maxDigits = minDigits;
  }
  const infos = newCodeInfos();
  return pruneCodeInfos(infos, { minDigits, maxDigits, pruneLeaves });
}

export function ancestors(code: string, minDigits: number) {
  const result: NaicsCodeInfo[] = [];
  let current: NaicsCodeInfo | undefined = allCodeInfos[code];
  while (current && digitsOf(current.code) >= minDigits) {
    result.unshift(current);
    current = current.parent;
  }
  return result;
}

export function ancestorsAt(code: string, ...digits: number[]) {
  const result: NaicsCodeInfo[] = [];
  let current: NaicsCodeInfo | undefined = allCodeInfos[code];
  while (current) {
    result.unshift(current);
    current = current.parent;
  }
  const resultByDigits = Object.fromEntries(
    result.map((info) => [digitsOf(info.code), info] as const)
  );
  return digits.map((d) => resultByDigits[d]);
}

export function* walkInfos(
  codeInfos: NaicsCodeInfo[]
): Generator<NaicsCodeInfo> {
  for (const info of codeInfos) {
    yield info;
    yield* walkInfos(Object.values(info.children));
  }
}
