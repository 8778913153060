import { NaicsCodeInfo } from './types';
import { walkInfos } from './index';

export function isRange(code: string) {
  return code.includes('-');
}

export function asRange(code: string): [string, string] {
  const parts = code.split('-');
  if (parts.length === 1) {
    return [code, code];
  }
  const [start, end] = parts;
  return [start, end];
}
export function digitsOf(code: string) {
  const [start, end] = asRange(code);
  if (start.length !== end.length) {
    throw new Error('Start and end of code range have different lengths');
  }
  return start.length;
}
/**
 * Check if the parent code is one digit shorter than the child code, and also a prefix of the child code.
 *
 * Also supports parent code ranges specified as e.g. '31-33', which should be the parent code of all child codes
 * starting with '31' or '32' or '33'.
 * @param parentCode
 * @param childCode
 */
export function isParentOf(parentCode: string, childCode: string) {
  if (isRange(childCode)) {
    // only sectors are code ranges so this shouldn't ever happen
    throw new Error('Child code cannot be a range');
  }
  const [start, end] = asRange(parentCode);
  if (digitsOf(parentCode) + 1 !== childCode.length) {
    return false;
  }
  return start <= childCode && childCode < `${end}~`;
}

/**
 * Check if the parent code is shorter than the child code, and also a prefix of the child code.
 *
 * Also supports parent code ranges specified as e.g. '31-33', which should be the parent code of all child codes
 * starting with '31' or '32' or '33'.
 * @param ancestorCode
 * @param childCode
 */
export function isAncestorOf(ancestorCode: string, childCode: string) {
  if (digitsOf(ancestorCode) >= digitsOf(childCode)) {
    return false;
  }
  if (isRange(childCode)) {
    // only sectors are code ranges so this shouldn't ever happen
    throw new Error('Child code cannot be a range');
  }
  const [start, end] = asRange(ancestorCode);
  return start <= childCode && childCode < `${end}~`;
}

/**
 * Get all code infos of the given length mapped by code.
 */
export function getCodeInfos(digits: number, infos: NaicsCodeInfo[]) {
  return Object.fromEntries(
    infos
      .filter((info) => digitsOf(info.code) === digits)
      .map((info) => [info.code, info])
  );
}

function pruneLoneLeaves(codeInfos: NaicsCodeInfo[]): number {
  const loneLeaves = [...walkInfos(codeInfos)].filter((info) => {
    return (
      Object.keys(info.children).length === 0 &&
      info.parent &&
      Object.keys(info.parent.children).length === 1
    );
  });
  for (const leaf of loneLeaves) {
    if (leaf.parent) leaf.parent.children = {};
  }
  return loneLeaves.length;
}

export function pruneCodeInfos(
  infos: NaicsCodeInfo[],
  {
    minDigits,
    maxDigits,
    pruneLeaves,
  }: {
    minDigits: number;
    maxDigits: number;
    pruneLeaves: boolean;
  }
) {
  const prunedCodeInfos = infos;
  for (const info of prunedCodeInfos) {
    if (digitsOf(info.code) >= maxDigits) {
      info.children = Object.freeze({});
    }
  }
  // this should probably happen *after* getCodeInfos selects the levels of the
  // hierarchy, and after getting rid of parent references over the top

  // don't need it until we ever call this with minDigits > 2
  if (minDigits > 2 && pruneLeaves) {
    console.warn(
      'Pruning leaves with minDigits > 2 is not recommended - you can lose subtrees'
    );
  }
  if (pruneLeaves) {
    let removed = 0;
    do {
      removed = pruneLoneLeaves(prunedCodeInfos);
    } while (removed > 0);
  }
  return getCodeInfos(minDigits, prunedCodeInfos);
}

export function connectCodeInfos(infos: NaicsCodeInfo[]) {
  for (const info of infos) {
    if (isRange(info.code)) {
      // this should be all sectors, those don't have parents
      continue;
    }
    info.parent = infos.find((parentInfo) =>
      isParentOf(parentInfo.code, info.code)
    );
    if (info.parent) {
      info.parent.children[info.code] = info;
    }
  }

  return infos;
}
