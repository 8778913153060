import { NaicsCodeInfo } from '../../../../utils/naics/types';
import { walkInfos } from '../../../../utils/naics';

/**
 * Determine the selection state of every item in the hierarchy, given the codes
 * of the selected leaf nodes.
 *
 * @return A record of code -> selection state (true/false/null)
 */
export function getSelectionState(
  rootInfos: Record<string, NaicsCodeInfo>,
  value: string[]
): Record<string, boolean | null> {
  const result: Record<string, boolean | null> = {};

  function _getSelectionState(codeInfos: Record<string, NaicsCodeInfo>) {
    for (const code in codeInfos) {
      const info = codeInfos[code];
      if (Object.keys(info.children).length === 0) {
        result[code] = value.includes(code);
      } else {
        _getSelectionState(info.children);
        const childrenStates = Object.values(info.children).map(
          (child) => result[child.code]
        );
        if (childrenStates.every((state) => state === true)) {
          result[code] = true;
        } else if (childrenStates.every((state) => state === false)) {
          result[code] = false;
        } else {
          result[code] = null;
        }
      }
    }
  }

  _getSelectionState(rootInfos);
  return result;
}

/**
 * Determine which codes in the hierarchy should be visible based on the search
 * filter. Codes that match the filter will be visible, along with their
 * ancestors.
 *
 * @return A record of code -> true for codes that should be visible.
 */
export function getMatchingCodes(
  rootInfos: NaicsCodeInfo[],
  searchFilter: (info: NaicsCodeInfo) => boolean
) {
  const result: Record<string, boolean> = {};

  for (const info of walkInfos(rootInfos)) {
    if (searchFilter(info)) {
      let current: typeof info | undefined = info;
      do {
        result[current.code] = true;
        current = current.parent;
      } while (current);
    }
  }
  return result;
}
