export const hiddenCss = {
  overflow: 'hidden',
  visibility: 'hidden',
  display: 'none',
};

export const visibleCss = {
  overflow: undefined,
  visibility: undefined,
  display: undefined,
};

export function sxVisibleIf(value: boolean) {
  return value ? visibleCss : hiddenCss;
}
