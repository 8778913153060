import React, { useRef, InputHTMLAttributes } from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  ButtonProps,
  Stack,
  Typography,
} from '@mui/material';
import { theme } from 'utils/theme';
import { FileUpload } from 'components/common/Buttons/FileUpload';
import {
  CsvUploadModal,
  CsvUploadModalFieldInfo,
} from 'components/ProspectList/ICPBuilder/CsvUploadModal';
import { FilterItemFullOptions, Option } from 'hooks/Prospects/useIcpFilter';
import { SimpleModal } from 'components/common/Modal/SimpleModal';
import { AutoCompleteTags } from 'components/ProspectList/AutoCompleteWithUpload/AutoCompleteCustomTags';
import { TextFieldInput } from 'components/ProspectList/AutoCompleteWithUpload/AutoCompleteInput';
import { AutoCompleteOption } from 'components/ProspectList/AutoCompleteWithUpload/AutoCompleteOption';
import {
  UseAutoCompleteCallbacks,
  useAutoCompleteWithUpload,
} from 'hooks/Prospects/useAutoCompleteWithUpload';
import { endpoints } from 'variables/endpoint-urls';
import { MuiIconManifest } from 'utils/iconManifest';
import { appendOrReplace } from 'utils/appendOrReplace';
import { formatNumber } from 'utils/util';
import { usePosthog } from 'analytics';

export interface TextFieldWithUploadProps<TField extends string>
  extends UseAutoCompleteCallbacks<TField> {
  // Static list of popup options.
  options: Option[];
  placeholder?: string;
  id?: string;
  // Current selected values. Externally controlled.
  value: Option[];
  label: string;
  disabled?: boolean;
  allowUpload?: boolean;
  fullOptions?: FilterItemFullOptions;
  isLink?: boolean;
  unique?: boolean;
  fetch?: boolean;
  disabledCustomValues?: boolean;
  onRemoveItemClicked?: (value: Option) => void;
  // Which column matching fields to display in the upload modal.
  uploadModalFields: CsvUploadModalFieldInfo<TField>[];
  // Horizontal divider between fields in the upload modal.
  uploadModalSeparator?: string;
  // Additional props for the text input.
  textInputProps?: AutocompleteRenderInputParams['inputProps'];
  // Additional props for the file input.
  fileInputProps?: InputHTMLAttributes<HTMLInputElement>;
  // Additional props for the upload button.
  uploadButtonProps?: ButtonProps;
}

export function TextFieldWithUpload<TField extends string>({
  options,
  placeholder,
  id,
  value,
  label,
  disabled,
  allowUpload,
  isLink,
  fullOptions,
  unique,
  fetch,
  disabledCustomValues,
  onValueChanged,
  onRemoveItemClicked,
  afterHandleCSVSubmit,
  endpointFunction = endpoints.autocomplete.get.prospects,
  filterFetchedOptions,
  filterUploadedOptions,
  formatUploadedOptions,
  uploadModalFields,
  uploadModalSeparator,
  textInputProps,
  fileInputProps,
  uploadButtonProps,
}: TextFieldWithUploadProps<TField>) {
  const { sendPosthogEvent } = usePosthog();

  const autoCompleteRef = useRef<HTMLDivElement>(null);
  const {
    openModal,
    setOpenModal,
    mainLabel,
    showConfirmUpload,
    csvData,
    formatedLabel,
    file,
    loadingFetch,
    inputValue,
    mapOptions,
    handleChange,
    fetchAutocompleteDebounced,
    handleFile,
    handleSubmit,
    setShowConfirmUpload,
    selectedColumns,
    setSelectedColumns,
    setInputValue,
  } = useAutoCompleteWithUpload({
    options,
    value,
    label,
    unique,
    fetch,
    disabledCustomValues,
    autoCompleteRef,
    onValueChanged,
    afterHandleCSVSubmit,
    endpointFunction,
    filterFetchedOptions,
    filterUploadedOptions,
    formatUploadedOptions,
  });

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: `${theme.shape.borderRadius}px`,
      }}
    >
      <SimpleModal
        maxWidth="sm"
        open={openModal}
        setOpen={setOpenModal}
        disableConfirm={!selectedColumns.some((col) => col.value.trim())}
        title={'Upload ' + mainLabel}
        subtitle={
          showConfirmUpload
            ? `You are about to upload ${formatNumber(
                csvData.rows.length
              )} rows. Please note that we can only display 100 entries on this page, but all rows will be processed.`
            : `Select the column in your file containing the ${mainLabel}.`
        }
        slotProps={{
          dialogContentProps: {
            style: {
              paddingTop: 0,
            },
          },
        }}
        children={
          showConfirmUpload ? (
            <Typography variant="body1">
              The remaining rows will be processed in the backend and reflected
              in your results when complete.
            </Typography>
          ) : (
            <CsvUploadModal
              label={formatedLabel}
              file={file}
              mainLabel={mainLabel}
              columns={csvData.columns}
              csvError={csvData.csvError}
              setSelectedColumn={(col) => {
                // Merge selected column names into array.
                setSelectedColumns(
                  // TODO: extract to function higher up in the tree.

                  (selectedColumns) => {
                    return appendOrReplace(
                      selectedColumns,
                      col,
                      (a, b) =>
                        a.fieldConfig.fieldName === b.fieldConfig.fieldName
                    );
                  }

                  // uniq([...selectedColumns, col as string])
                );
              }}
              fields={uploadModalFields}
              separator={uploadModalSeparator}
            />
          )
        }
        closeAfterSubmit={false}
        onConfirm={() => {
          if (!showConfirmUpload && csvData.rows.length > 100) {
            // show the 100 rows limit modal
            setShowConfirmUpload(true);
            setOpenModal(true);

            // track the event when the user tries to upload more than 100 rows
            sendPosthogEvent('List Wizard > Upload > More than 100', {
              label: mainLabel,
              numRows: csvData.rows.length,
            });
            return;
          }
          handleSubmit();
        }}
        onCancel={() => setOpenModal(false)}
      />
      <Autocomplete
        id={id || 'auto-complete'}
        fullWidth
        sx={{
          padding: value.length > 0 ? 0 : 2,
        }}
        autoHighlight
        ref={autoCompleteRef}
        filterSelectedOptions
        value={value}
        multiple
        noOptionsText={
          fetch
            ? loadingFetch
              ? 'Loading...'
              : inputValue.length > 0
              ? 'No options found'
              : 'Type to search'
            : `No ${formatedLabel.toLowerCase()} found`
        }
        limitTags={unique ? 1 : undefined}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => {
          return option.value?.toLowerCase() === value.value?.toLowerCase();
        }}
        size="small"
        options={mapOptions()}
        onChange={handleChange}
        renderTags={(value, getTagProps) => (
          <AutoCompleteTags
            value={value.slice(0, 100) as Option[]}
            isLink={isLink || false}
            getTagProps={getTagProps}
            allowCsvUpload={allowUpload}
            length={value.length}
            handleDelete={onRemoveItemClicked}
          />
        )}
        renderInput={(params) => {
          const combinedParams = {
            ...params,
            inputProps: {
              ...params.inputProps,
              ...(textInputProps ? textInputProps : {}),
            },
          };

          return (
            <TextFieldInput
              inputValue={inputValue}
              setInputValue={fetch ? fetchAutocompleteDebounced : setInputValue}
              placeholder={placeholder || `Add ${formatedLabel}`}
              params={combinedParams}
            />
          );
        }}
        renderOption={(props, option) => {
          const optionAdapted = option as Option;
          const fullOption = fullOptions
            ? fullOptions.find((item) => item.title === optionAdapted.label)
            : null;

          if (!fullOption)
            return (
              <AutoCompleteOption
                // @ts-expect-error
                key={optionAdapted.value}
                {...props}
                option={{
                  label: optionAdapted.label,
                  value: optionAdapted.value,
                  disabled: optionAdapted.disabled,
                }}
              />
            );

          return (
            <AutoCompleteOption
              // @ts-expect-error
              key={optionAdapted.value}
              {...props}
              option={{
                label: fullOption.title,
                value: optionAdapted.value,
                description: fullOption.description,
              }}
            />
          );
        }}
      />
      {allowUpload && (
        <Box pr={2}>
          <FileUpload
            buttonProps={{
              variant: 'roundedText',
              startIcon: (
                <MuiIconManifest.UploadFileOutlinedIcon fontSize="small" />
              ),
              sx: {
                pr: 2,
              },
              ...uploadButtonProps,
            }}
            accept=".csv, .xls, .xlsx"
            label="Upload"
            handleFile={handleFile}
            inputProps={fileInputProps}
          />
        </Box>
      )}
    </Stack>
  );
}
