import { WizardNaicsIndustrySelectQuestion } from '../../WizardConfig';
import { Stack, Typography } from '@mui/material';
import { WizardPagination } from '../../WizardPagination';
import { useForm } from 'react-hook-form-mui';
import { Wizard } from '../../Wizard';
import { useHistory } from 'react-router';
import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setSnacksQueue } from '../../../../redux/reducers/snacks';
import { usePosthog } from '../../../../analytics';
import { newPrunedCodeInfos } from '../../../../utils/naics';
import { NaicsIndustrySelect } from './NaicsIndustrySelect';

export interface NaicsIndustrySelectQuestionProps<TWizard = Wizard> {
  wizard: TWizard;
  // name will be included_naics2022_codes
  question: WizardNaicsIndustrySelectQuestion;
  onSubmit?: (response: any) => void;
  initialValue?: string[];
}
export function NaicsIndustrySelectQuestion({
  wizard,
  question,
  onSubmit,
  initialValue = [],
}: NaicsIndustrySelectQuestionProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultValues: Record<typeof question.name, string[]> = {};
  const formRef = useRef<HTMLFormElement>(null);

  const { sendPosthogEvent } = usePosthog();

  defaultValues[question.name] = initialValue;

  const form = useForm({
    defaultValues,
  });

  function handleChange(newValue: string[]) {
    form.setValue(question.name, newValue);
  }

  function submitForm(values: typeof defaultValues) {
    try {
      onSubmit?.(values[question.name]);
    } catch (e) {
      // Localstorage can't handle this much data; show a nice message.
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        sendPosthogEvent('List Wizard > Upload Error > Too Large', {
          questionId: question.id,
          wizardId: wizard.config.id,
          wizardVersion: wizard.config.version,
        });
        dispatch(
          setSnacksQueue({
            message:
              'Your upload is too large for the wizard to handle. Try again with fewer rows.',
          })
        );
      }
    }
  }

  function onNextClick() {
    formRef.current?.requestSubmit();
  }

  function onPreviousClick() {
    history.goBack();
  }

  const minDigits = question.minDigits;
  const maxDigits = question.maxDigits;

  if (minDigits < 2 || minDigits > 6) {
    throw new Error('minDigits must be between 2 and 6');
  }
  if (maxDigits < 2 || maxDigits > 6) {
    throw new Error('maxDigits must be between 2 and 6');
  }
  if (minDigits > maxDigits) {
    throw new Error('minDigits must be less than or equal to maxDigits');
  }

  const codeInfos = useMemo(
    () =>
      newPrunedCodeInfos({
        minDigits: minDigits,
        maxDigits: maxDigits,
      }),
    [minDigits, maxDigits]
  );

  // the label and description can be a function that takes the wizard as an argument
  const questionLabel =
    typeof question.label === 'function'
      ? question.label(wizard)
      : question.label;
  const description =
    typeof question.description === 'function'
      ? question.description(wizard)
      : question.description;

  return (
    <form onSubmit={form.handleSubmit(submitForm)} ref={formRef}>
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Typography fontWeight={500} variant="h6">
            {questionLabel}
          </Typography>
          {question.description ? (
            <Typography variant="body2">{description}</Typography>
          ) : null}
          <NaicsIndustrySelect
            codeInfos={codeInfos}
            value={form.watch(question.name)}
            onChange={handleChange}
          />
        </Stack>
        <WizardPagination
          wizard={wizard}
          question={question}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      </Stack>
    </form>
  );
}
