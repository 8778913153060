import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * Used when a question is not found. Which shouldn't happen but most likely happened due to a change in the wizard config.
 */
export const NotFoundQuestion = () => {
  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Typography fontWeight={500} variant="h6">
          Question not found.
        </Typography>
        <Typography>
          Due to recent changes in the list builder functionality, this section
          is no longer accessible. Please contact our support team at{' '}
          <a href="mailto:support@vendelux.com">support@vendelux.com</a> for
          assistance with your list, or{' '}
          <Link to="/app/myprospects/lists">
            return to your lists dashboard
          </Link>
          .
        </Typography>
      </Stack>
    </Stack>
  );
};
