import { NaicsCodeInfo } from '../../../../utils/naics/types';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { memo, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
} from '@mui/material';
import { walkInfos } from '../../../../utils/naics';
import { MuiIconManifest } from '../../../../utils/iconManifest';
import TextField from '@mui/material/TextField';
import { sxVisibleIf } from '../../../../utils/visiblyHidden';
import ClearIcon from '@mui/icons-material/Clear';
import { HighlightTerms } from '../../../common/Widgets/HighlightTerms';
import { getMatchingCodes, getSelectionState } from './helpers';

export interface NaicsIndustrySelectProps {
  codeInfos: Record<string, NaicsCodeInfo>;
  value: string[];
  onChange: (value: string[]) => void;
}

interface NaicsIndustrySelectUIProps {
  value: string[];
  searchText: string;
  setSearchText: (value: ((prevState: string) => string) | string) => void;
  setExpandedCodes: (
    value: ((prevState: string[]) => string[]) | string[]
  ) => void;
  expandedCodes: string[];
  rootInfos: Record<string, NaicsCodeInfo>;
  setSelectedCode: (code: string, val: boolean) => void;
}

function _NaicsIndustrySelectUI({
  value,
  searchText,
  setSearchText,
  setExpandedCodes,
  expandedCodes,
  rootInfos,
  setSelectedCode,
}: NaicsIndustrySelectUIProps) {
  const expandableCodes = useMemo(
    () =>
      [...walkInfos(Object.values(rootInfos))]
        .filter(({ children }) => Object.entries(children).length > 0)
        .map(({ code }) => code),
    [rootInfos]
  );

  const searchTerms = useMemo(
    () =>
      searchText
        .trim()
        .toLowerCase()
        .split(' ')
        .filter((term) => term),
    [searchText]
  );

  const searchFilter = useCallback(
    (codeInfo: NaicsCodeInfo) => {
      const haystackTerms = codeInfo.title
        .trim()
        .toLowerCase()
        .split(' ')
        .filter((term) => term);
      return searchTerms.every((term) =>
        haystackTerms.some((haystackTerm) => haystackTerm.startsWith(term))
      );
    },
    [searchTerms]
  );

  const selectionState = useMemo(
    () => getSelectionState(rootInfos, value),
    [rootInfos, value]
  );

  const renderLabel = useCallback(
    (codeInfo: NaicsCodeInfo) => {
      return (
        <>
          <FormControlLabel
            sx={{
              m: 0,
            }}
            control={
              <Checkbox
                size={'small'}
                checked={selectionState[codeInfo.code] ?? false}
                indeterminate={selectionState[codeInfo.code] === null}
                onChange={(evt) => {
                  setSelectedCode(codeInfo.code, evt.currentTarget.checked);
                  evt.stopPropagation();
                }}
              />
            }
            label={<HighlightTerms text={codeInfo.title} terms={searchTerms} />}
          />
        </>
      );
    },
    [searchTerms, selectionState, setSelectedCode]
  );

  const matchingCodes = useMemo(() => {
    return getMatchingCodes(Object.values(rootInfos), searchFilter);
  }, [rootInfos, searchFilter]);

  const renderTree = useCallback(
    (codeInfos: Record<string, NaicsCodeInfo>) => {
      return Object.values(codeInfos).map((info) => (
        <TreeItem
          key={info.code}
          itemId={info.code}
          label={renderLabel(info)}
          sx={sxVisibleIf(matchingCodes[info.code])}
        >
          {renderTree(info.children)}
        </TreeItem>
      ));
    },
    [matchingCodes, renderLabel]
  );

  const realExpandedCodes = useMemo(() => {
    return searchTerms.length === 0
      ? expandedCodes
      : Object.keys(matchingCodes).filter((code) => matchingCodes[code]);
  }, [expandedCodes, matchingCodes, searchTerms]);

  return (
    <>
      <Stack>
        <TextField
          placeholder="Search"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MuiIconManifest.SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchText && (
              <InputAdornment position="end">
                <ClearIcon
                  onClick={() => setSearchText('')}
                  style={{ cursor: 'pointer' }}
                />
              </InputAdornment>
            ),
          }}
          value={searchText}
          onChange={(evt) => setSearchText(evt.currentTarget.value)}
        />
        {searchTerms.length === 0 ? (
          <Stack direction="row" spacing={2} sx={{ margin: 'auto' }}>
            <Button onClick={() => setExpandedCodes(expandableCodes)}>
              Expand All
            </Button>
            <Button onClick={() => setExpandedCodes([])}>Collapse All</Button>
          </Stack>
        ) : null}
        <SimpleTreeView
          disableSelection
          expansionTrigger={'iconContainer'}
          expandedItems={realExpandedCodes}
          onExpandedItemsChange={(_evt, codes) => setExpandedCodes(codes)}
        >
          {renderTree(rootInfos)}
        </SimpleTreeView>
      </Stack>
    </>
  );
}
const NaicsIndustrySelectUI = memo(_NaicsIndustrySelectUI);

export function NaicsIndustrySelect({
  codeInfos: rootInfos,
  value,
  onChange,
}: NaicsIndustrySelectProps) {
  const [expandedCodes, setExpandedCodes] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');

  const infosByCode = useMemo(
    () =>
      Object.fromEntries(
        [...walkInfos(Object.values(rootInfos))].map((info) => [
          info.code,
          info,
        ])
      ),
    [rootInfos]
  );

  const setSelectedCode = useCallback(
    (code: string, val: boolean) => {
      // all leaves in subtree
      const codesToSelect = new Set(
        [...walkInfos([infosByCode[code]])]
          .filter(({ children }) => Object.keys(children).length === 0)
          .map(({ code }) => code)
      );

      onChange(
        val
          ? [...value, ...codesToSelect]
          : value.filter((code) => !codesToSelect.has(code))
      );
    },
    [infosByCode, onChange, value]
  );

  return (
    <NaicsIndustrySelectUI
      value={value}
      searchText={searchText}
      setSearchText={setSearchText}
      setExpandedCodes={setExpandedCodes}
      expandedCodes={expandedCodes}
      rootInfos={rootInfos}
      setSelectedCode={setSelectedCode}
    />
  );
}
